import { REVIEWS_WIDGET_NAMES } from "@/constants/widget-names";
import ReviewEntity from "@apps/reviews/entities/ReviewEntity";
import { applyTransaction } from "@datorama/akita";
import { BehaviorSubject, Observable, Subscription, tap } from "rxjs";

import ReviewsGalleryQuery from "./queries/ReviewsGalleryQuery";
import ReviewsGalleryStore from "./stores/ReviewsGalleryStore";

type OpenReviewModal = {
    reviews$: Observable<ReviewEntity[]>;
    activeReviewId: number;
    activeImageIndex?: number;
    widget?: REVIEWS_WIDGET_NAMES;
    onVote?: (reviewId: number) => void;
};

export default class ReviewsGalleryManager {
    public activeReview$ = this.reviewsGalleryQuery.active$;
    public activeImageIndex$ = new BehaviorSubject<number | undefined>(
        undefined
    );
    public showModal$ = new BehaviorSubject<boolean>(false);
    public reviewsSubscription: Subscription | undefined = undefined;
    public widget$ = new BehaviorSubject<REVIEWS_WIDGET_NAMES | undefined>(
        undefined
    );
    public onVote: ((reviewId: number) => void) | undefined = undefined;

    constructor(
        private readonly reviewsGalleryStore: ReviewsGalleryStore,
        private readonly reviewsGalleryQuery: ReviewsGalleryQuery
    ) {}

    openReviewModal({
        reviews$,
        activeReviewId,
        activeImageIndex,
        widget,
        onVote,
    }: OpenReviewModal) {
        this.reviewsSubscription = reviews$
            .pipe(
                tap((reviews) => {
                    applyTransaction(() => {
                        this.reviewsGalleryStore.set(reviews);
                        this.reviewsGalleryStore.setActive(activeReviewId);
                    });
                })
            )
            .subscribe();
        this.showModal$.next(true);
        this.widget$.next(widget);
        this.activeImageIndex$.next(activeImageIndex);
        this.onVote = onVote;
    }

    closeReviewModal() {
        applyTransaction(() => {
            this.reviewsGalleryStore.update([]);
            this.reviewsGalleryStore.setActive(null);
        });
        this.showModal$.next(false);

        if (this.reviewsSubscription) {
            this.reviewsSubscription.unsubscribe();
        }
    }

    next() {
        this.reviewsGalleryStore.setActive({ next: true });
    }

    prev() {
        this.reviewsGalleryStore.setActive({ prev: true });
    }
}
