import { GW_ADDITIONAL_INFO_SELECTOR } from "@/constants";
import { getJsonFromScript } from "@lib/utils";
import {
    Infer,
    boolean,
    create,
    literal,
    number,
    object,
    string,
    type,
    union,
} from "superstruct";
import { inject, singleton } from "tsyringe";

import { LoggerInterface } from "@interfaces/LoggerInterface";

import { loginApplicationLoggerToken } from "../../apps/login/tokens";

export const CustomerInfoSchema = object({
    email: string(),
    id: number(),
    isB2b: boolean(),
});

const AdditionalInfoSchema = type({
    GW_CUSTOMER_INFO: union([CustomerInfoSchema, literal(null)]),
});

@singleton()
class CustomerInfoProvider {
    private customerInfo: Infer<typeof CustomerInfoSchema> | null = null;
    constructor(
        @inject(loginApplicationLoggerToken)
        private readonly logger: LoggerInterface
    ) {}
    readFromPage(): void {
        if (this.customerInfo) return;
        const additionalInfoData = getJsonFromScript(
            GW_ADDITIONAL_INFO_SELECTOR
        );
        const additionalInfo = create(additionalInfoData, AdditionalInfoSchema);
        this.customerInfo = additionalInfo.GW_CUSTOMER_INFO;
        this.logger.info("customer info provider is ready", {
            customerInfo: this.customerInfo,
        });
    }
    getEmail(): string | null {
        return this.customerInfo?.email || null;
    }
    getId(): number | null {
        return this.customerInfo?.id || null;
    }
    getIsB2b(): boolean {
        return this.customerInfo?.isB2b || false;
    }
}

export default CustomerInfoProvider;
